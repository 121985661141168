import { Injectable } from '@angular/core';
import { LocalStoreService } from './local-store.service';
import { Router } from '@angular/router';
import { of, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // Only for demo purpose
  authenticated = false;
  // readonly rootUrl="http://localhost:50718";
  readonly rootUrl = environment.apiEndpoint;
  // readonly rootUrl="http://localhost:62791";
  constructor(private store: LocalStoreService, private router: Router, private http: HttpClient) {
    this.checkAuth();
  }

  checkAuth() {
     this.authenticated = this.store.getItem('demo_login_status');
  }

  getuser() {
    return of({});
  }

  getuserrole() {
    // localStorage.removeItem('userToken');
    // alert(data + 'service' + localStorage.getItem('userToken'));
    const requestHeader = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': 'Bearer ' + this.store.getItem('userToken')
    });
    return this.http.get(this.rootUrl + '/api/Account/GetUserRole', {headers: requestHeader});
  }

  signin(credentials) {
     let userType: string;
     // localStorage.removeItem('userToken');
     const data = 'grant_type=password&username=' + credentials.email + '&password=' + credentials.password;
     // alert(data + 'service' + localStorage.getItem('userToken'));
     const requestHeader = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
     return this.http.post(this.rootUrl + '/token', data, {headers: requestHeader});
  }

  // signin(credentials)
  //  {
  //   let userType:string;
  //   //localStorage.removeItem('userToken');
  //   var data="grant_type=password&username="+ credentials.email +"&password=" + credentials.password;
  //   //alert(data + 'service' + localStorage.getItem('userToken'));
  //   var requestHeader = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
  //   this.http.post(this.rootUrl + '/token',data,{headers: requestHeader}).subscribe((data: any)=> {
  //     //localStorage.setItem('userName',this.userName);
  //     //localStorage.setItem('userToken',data.access_token);
  //     alert(data.access_token);
  //     //this.navbar.visible = true;
  //     //this.isLoginError = false;
  //     //this.router.navigate(['/demo']);
  //     //this.getuser();
  //     return of({'user':'admin'}).pipe(delay(1500));
  //     //this.userType = "admin";
  //   },
  //   (err: HttpErrorResponse)=> {
  //     alert("Invalid Username/Password" + JSON.stringify(err));
  //     //this.isLoginError = true;
  //   });
  //   //return of({'user': this.userType}).pipe(delay(1500));
  //   // let userType:string;
  //   // if(credentials.password=='1234')
  //   //   userType='sa';
  //   // else
  //   //   userType='admin';
  //   // this.authenticated = true;
  //   // this.store.setItem("demo_login_status", true);
  //   // this.store.setItem("usertype",userType);
  //   //return of({'user': userType}).pipe(delay(1500));
  // }

  signout() {
    this.authenticated = false;
    this.store.setItem('demo_login_status', false);
    this.store.setItem('usertype', '');
    this.router.navigateByUrl('/sessions/signin');
  }
}
